// 转换查询参数为url友好格式
export const queryToUrlParams = (query) => {
  const { type, flights } = query

  const arrFlight = flights.map((flight) => {
    return `${flight.depCity};${flight.arrCity};${flight.depDate}`
  })
  const arrFlightStr = arrFlight.join('|')

  return `${type}:${arrFlightStr}`
}

// 转换查询参数url友好格式为查询参数为object
export const urlParamsToQuery = (urlParam) => {
  try {
    urlParam = decodeURI(urlParam)

    const parseOut = urlParam.split(':')

    const flightArr = parseOut[1].split('|')
    const flightObjArr = flightArr.map((f) => {
      const parse = f.split(';')

      return {
        depCity: parse[0],
        arrCity: parse[1],
        depDate: parse[2]
      }
    })

    return {
      type: parseOut[0],
      flights: flightObjArr
    }
  } catch (e) {
    throw new Error(e)
  }
}
