import { Popover, Calendar, Input, Icon } from 'ant-design-vue'
import PriceCalendar from './Calendar'

PriceCalendar.install = (Vue) => {
  Vue.component(PriceCalendar.name, PriceCalendar)
  Vue.use(Input)
  Vue.use(Popover)
  Vue.use(Calendar)
  Vue.use(Icon)
}

export default PriceCalendar
