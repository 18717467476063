(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("ant-design-vue"), require("moment"));
	else if(typeof define === 'function' && define.amd)
		define(["ant-design-vue", "moment"], factory);
	else if(typeof exports === 'object')
		exports["ace-ui-eking"] = factory(require("ant-design-vue"), require("moment"));
	else
		root["ace-ui-eking"] = factory(root["ant-design-vue"], root["moment"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__4e72__, __WEBPACK_EXTERNAL_MODULE_c32d__) {
return 