<template>
  <div class="search-bar">
    <div class="search-tabs">
      <div class="tabs-head">
        <div
          class="tab-item"
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ active: tab.name === activeTab }"
          @click="onToggleTab(tab)"
        >
          {{ tab.title }}
        </div>
      </div>
      <div class="tab-main">
        <div class="search-box" v-show="activeTab !== 'multi'">
          <div class="search-container">
          <div class="search-row search-title-row">
            <div class="search-col col-l">
              <span>出发地</span>
            </div>
            <div class="search-col col-c"></div>
            <div class="search-col col-r">
              <span>目的地</span>
            </div>
          </div>
          <div class="search-row">
            <div class="search-col col-l">
              <j-flight-city-selector
                size="large"
                class="search-bar-city-selector"
                :value="queryFlights[0].depCity"
                @change="onFlightDepChange($event, queryFlights[0])"
                placeholder="拼音/汉字/三字码"
              />
            </div>
            <div class="search-col col-c">
              <div class="search-switch-icon" @click="onSwitchSearch"></div>
            </div>
            <div class="search-col col-r">
              <j-flight-city-selector
                size="large"
                class="search-bar-city-selector"
                :value="queryFlights[0].arrCity"
                @change="onFlightArrChange($event, queryFlights[0])"
                placeholder="拼音/汉字/三字码"
              />
            </div>
          </div>
          <div class="search-row">
            <div class="search-col col-l">
              <div class="search-bar-calendar-warp">
                <label class="search-bar-calendar-label">
                  <Calendar
                    size="large"
                    class="search-bar-calendar"
                    v-model="queryFlights[0].depDate"
                  />
                  <a-icon type="calendar"></a-icon>
                </label>
                <template v-if="activeTab === 'round'">
                    <a-icon type="swap"></a-icon>
                    <label class="search-bar-calendar-label">
                    <Calendar
                      size="large"
                      class="search-bar-calendar"
                      v-model="queryFlights[0].arrDate"
                    />
                    <a-icon type="calendar"></a-icon>
                    </label>
                </template>
              </div>
            </div>
            <div class="search-col col-c"></div>
            <div class="search-col col-r">
              <a-button type="primary" size="large" class="button-radius" :loading="loading" block @click="onQueryFlight">查询座位和价格</a-button>
            </div>
          </div>
          </div>
        </div>
        <div class="search-box" v-show="activeTab === 'multi'">
          <div class="search-multi-container">
            <div class="search-row search-title-row">
              <div class="search-col col-index"></div>
              <div class="search-col col-l">
                <span>出发地</span>
              </div>
              <div class="search-col col-r">
                <span>目的地</span>
              </div>
              <div class="search-col col-c">
                出发日期
              </div>
            </div>
            <div class="search-row" v-for="(flightItem, index) in queryFlights" :key="index">
              <div class="search-col col-index">{{ index | flightIndexFilter}}</div>
              <div class="search-col col-l">
                <j-flight-city-selector
                  ref="depCitySelector"
                  size="large"
                  class="search-bar-city-selector"
                  @change="onFlightDepChange($event, flightItem)"
                  placeholder="拼音/汉字/三字码"
                />
              </div>
              <div class="search-col col-r">
                <j-flight-city-selector
                  size="large"
                  class="search-bar-city-selector"
                  @change="onFlightArrChange($event, flightItem)"
                  placeholder="拼音/汉字/三字码"
                />
              </div>
              <div class="search-col col-c">
                  <Calendar
                    size="large"
                    class="search-bar-calendar"
                    v-model="flightItem.depDate"
                  />
              </div>
              <div class="search-col col-c">
                <a-icon v-show="queryFlights.length > 1" type="delete" @click="onDeleteFlight(index)"></a-icon>
              </div>
            </div>
            <div class="search-row search-title-row">
              <div class="search-col col-index"></div>
              <div class="search-col col-l">
                <a-button type="primary" size="large" class="button-radius" :loading="loading" block @click="addMultiFlight">添加行程</a-button>
              </div>
              <div class="search-col col-r">
                <a-button type="primary" size="large" class="button-radius" :loading="loading" block @click="onQueryFlight">查询座位和价格</a-button>
              </div>
              <div class="search-col col-c">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import { Calendar } from 'ace-ui-eking'

export default {
  name: 'SearchBar',
  components: {
    Calendar
  },
  filters: {
    flightIndexFilter (index) {
      return ['第一程', '第二程', '第三程', '第四程'][index]
    }
  },
  props: {
    tab: {
      type: String,
      default: 'single'
    },
    loading: {
      type: Boolean,
      default: false
    },
    value: Object
  },
  watch: {
    tab: {
      handler (value) {
        this.activeTab = value || 'round'
      },
      immediate: true
    },
    activeTab (type) {
      this.freshQueryFlight(type)
    },
    value: {
      handler (query) {
        const { type, flights } = query
        if (type && flights) {
          this.fillQuery(query)
        }
      },
      immediate: true
    }
  },
  data () {
    return {
      tabs: [
        {
          name: 'round',
          title: '往返'
        },
        {
          name: 'multi',
          title: '多程'
        },
        {
          name: 'single',
          title: '单程'
        }
      ],
      activeTab: 'round',
      queryFlights: []
    }
  },
  created () {
    // 没有传model时，默认创建一个
    if (!this.queryFlights.length) {
      this.queryFlights.push({
        depCity: '',
        arrCity: '',
        depDate: moment().add(1, 'd'),
        arrDate: moment().add(2, 'd')
      })
    }
  },
  methods: {
    onSwitchSearch () {
      let cacheDepCity = ''
      cacheDepCity = this.queryFlights[0].depCity
      this.queryFlights[0].depCity = this.queryFlights[0].arrCity
      this.queryFlights[0].arrCity = cacheDepCity
    },
    onToggleTab (tab) {
      this.activeTab = tab.name
      this.$emit('toggle', tab)
    },
    onFlightDepChange ($event, flightItem) {
      flightItem.depCity = $event
    },
    onFlightArrChange ($event, flightItem) {
      flightItem.arrCity = $event
    },
    addMultiFlight () {
      if (this.queryFlights.length < 4) {
        this.queryFlights.push({
          depCity: '',
          arrCity: '',
          depDate: moment().add(1, 'd'),
          arrDate: moment().add(2, 'd')
        })
      } else {
        this.$message.warn('多程航段最多不能超过四程')
      }
    },
    freshQueryFlight (type) {
      const { queryFlights } = this

      if (type !== 'multi') {
        // 切其他类型时, 清空多程的数据
        if (queryFlights.length > 1) {
          queryFlights.splice(1, queryFlights.length - 1)
        }
      } else {
        // 切多程类型时, 自动加一条
        this.$nextTick(() => {
          this.addMultiFlight()
        })
      }
    },
    onQueryFlight () {
      const { queryFlights, activeTab } = this
      const queryArray = []
      const zeroFlight = queryFlights[0]
      let type = ''

      // 校验查询信息是否有空值
      if (activeTab !== 'multi') {
        if (zeroFlight.depCity === '' || zeroFlight.arrCity === '') {
          this.$message.error('请选择出发地或目的地')
          return
        }
      } else {
        const emptyFlights = queryFlights.filter((flight) => {
          return flight.depCity === '' || flight.arrCity === ''
        })

        if (emptyFlights.length) {
          this.$message.error('多行程请选择所有的出发地或目的地')
          return
        }
      }

      switch (this.activeTab) {
        case 'single':
          type = 'ST'
          queryArray.push({
            depCity: zeroFlight.depCity.toUpperCase(),
            arrCity: zeroFlight.arrCity.toUpperCase(),
            depDate: zeroFlight.depDate.format('YYYY-MM-DD')
          })
          break
        case 'round':
          type = 'RT'
          queryArray.push({
            depCity: zeroFlight.depCity.toUpperCase(),
            arrCity: zeroFlight.arrCity.toUpperCase(),
            depDate: zeroFlight.depDate.format('YYYY-MM-DD')
          })
          queryArray.push({
            depCity: zeroFlight.arrCity.toUpperCase(),
            arrCity: zeroFlight.depCity.toUpperCase(),
            depDate: zeroFlight.arrDate.format('YYYY-MM-DD')
          })
          break
        case 'multi':
          queryFlights.forEach((flight) => {
            queryArray.push({
              depCity: flight.depCity.toUpperCase(),
              arrCity: flight.arrCity.toUpperCase(),
              depDate: flight.depDate.format('YYYY-MM-DD')
            })
          })

          if (queryArray.length > 1) { // 大于两程都算多程
            type = 'MW'
          } else if (queryArray.length === 2) { // 两程时，简单判断属于LC联程情况
            if (queryArray[1].depCity === queryArray[0].arrCity) {
              type = 'LC'
            }
          }

          break
        default:
          break
      }

      if (type === 'RT') {
        if (moment(queryArray[1].depDate).isBefore(queryArray[0].depDate)) {
          this.$message.error('返程日期不能早于去程日期')
          return
        }
      }

      const query = {
        type,
        flights: queryArray
      }

      this.$emit('search', query)
      this.$emit('input', query)
    },
    onDeleteFlight (index) {
      if (this.queryFlights.length > 1) {
        this.queryFlights.splice(index, 1)
      }
    },
    // 回填查询参数方法
    fillQuery (query) {
      const { type, flights } = query
      const map = {
        ST: 'single',
        LC: 'single',
        RT: 'round',
        MW: 'multi'
      }
      this.activeTab = map[type]

      let queryFlights = []

      if (this.activeTab === 'round') {
        queryFlights = [{
          depCity: flights[0].depCity,
          arrCity: flights[0].arrCity,
          depDate: moment(flights[0].depDate),
          arrDate: flights[1] ? moment(flights[1].depDate) : moment(flights[0].depDate).add(1, 'd')
        }]
      } else {
        queryFlights = flights.map((item) => {
          return {
            depCity: item.depCity,
            arrCity: item.arrCity,
            depDate: moment(item.depDate),
            arrDate: moment(item.arrDate)
          }
        })
      }

      this.queryFlights = queryFlights
    }
  }
}
</script>

<style lang="less" scoped>
.search-bar {
  width: 984px;
}
.search-tabs {
  .tabs-head {
    display: flex;
  }

  .tab-main {
  }

  .tab-item {
    position: relative;
    flex: 1;
    text-align: center;
    height: 60px;
    line-height: 60px;
    font-size: 18px;
    background: rgba(255, 255, 255, 0.8);
    transition: all 0.3s;
    position: relative;
    cursor: pointer;

    &:after {
      content: "";
      height: 60%;
      width: 0;
      border-right: 1px solid #707070;
      position: absolute;
      left: -1px;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 10;
    }

    &.active {
      background: #fff;
      color: @joy-orange-color;

      &:after {
        display: none;
      }

      & + .tab-item {
        &:after {
          display: none;
        }
      }
    }

    &:first-of-type {
      &:after {
        display: none;
      }
    }
  }
}

::v-deep .search-bar-city-selector {
  .ant-select {
    display: block;
  }
  .ant-select-auto-complete.ant-select .ant-input {
    border: 0;
    background: #f5f7f9;
    border-radius: 30px;
    width: 100%;
    font-size: 14px;
    padding-left: 24px;
  }
}

.search-switch-icon {
  width: 32px;
  height: 32px;
  background: url(~@/assets/images/flight-toggle.png) no-repeat center;
  margin: 0 auto;
  cursor: pointer;
}

.search-bar-calendar-warp {
  background: #f5f7f9;
  border-radius: 30px;
  display: table;
  width: 100%;

  .search-bar-calendar-label {
    display: table-cell;
    position: relative;
  }

  .anticon-calendar {
    position: absolute;
    right: 24px;
    top: 14px;
  }
}

::v-deep .search-bar-calendar {
  .ant-input {
    border: 0;
    background: #f5f7f9;
    border-radius: 30px;
    font-size: 14px;
    padding-left: 24px;
  }
}

.search-box {
  padding: 20px;
  background: #fff;
  border-radius: 0px 0px 8px 8px;
}
.search-container {
  .search-row {
    width: 100%;
    display: table;

    &.search-title-row {
      color: @joy-darkblue-color;
      font-weight: bold;
      font-size: 16px;

      + .search-row {
        margin-top: 8px;
      }
    }

    + .search-row {
      margin-top: 20px;
    }
    .search-col {
      display: table-cell;

      &.col-l,
      &.col-r {
        width: 396px;
      }
    }
  }
}
.search-multi-container {
   .search-row {
    width: 100%;
    display: table;

    &.search-title-row {
      color: @joy-darkblue-color;
      font-weight: bold;
      font-size: 16px;

      + .search-row {
        margin-top: 8px;
      }
    }

    + .search-row {
      margin-top: 20px;
    }
    .search-col {
      display: table-cell;
      padding: 0 10px;

      &.col-index {
        width: 120px;
        text-align: center;
      }

      &.col-l,
      &.col-r {
        width: 296px;
      }
    }
  }
}

</style>
