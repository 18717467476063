<template>
  <div class="air-calendar">
    <a-popover
      trigger="click"
      :placement="placement"
      overlay-class-name="air-calendar-popover"
      v-model="show"
      @visibleChange="onVisibleChange"
    >
      <a-input ref="input" type="text" :size="size" :placeholder="placeholder" :value="model | format" read-only >
        <a-icon v-if="value && allowClear" slot="suffix" type="close-circle" @click="onClear" />
      </a-input>
      <div class="panel-box clearfix" slot="content">
        <div class="panel-loading" v-if="showLoading && loading">
          <a-spin size="large"></a-spin>
        </div>
        <a-calendar
          :class="{'panel-item': true, 'panel-active': activeIndex === 0}"
          :fullscreen="false"
          :value="prevValue"
          :disabledDate="prevDisabledDate"
          @select="onPrevSelect"
        >
          <template slot="headerRender">
            <div class="cell-header">
              <div :class="{'prev': true, 'disabled': prevDisable}" @click="onPrevChange">
                <a-icon type="caret-left" />
              </div>
              <span>{{ prevValue | month }}</span>
            </div>
          </template>
          <template slot="dateFullCellRender" slot-scope="value">
            <div class="ant-fullcalendar-value">{{ value | date }}</div>
            <div class="ant-fullcalendar-content" v-html="$options.filters.price(value, fares, minPrice)"></div>
          </template>
        </a-calendar>
        <a-calendar
          :class="{'panel-item': true, 'panel-active': activeIndex === 1}"
          :fullscreen="false"
          :value="nextValue"
          :disabledDate="nextDisabledDate"
          @select="onNextSelect"
        >
          <template slot="headerRender">
            <div class="cell-header">
              <span>{{ nextValue | month }}</span>
              <div class="next" @click="onNextChange">
                <a-icon type="caret-right" />
              </div>
            </div>
          </template>
          <template slot="dateFullCellRender" slot-scope="value">
            <div class="ant-fullcalendar-value">{{ value | date }}</div>
            <div class="ant-fullcalendar-content" v-html="$options.filters.price(value, fares, minPrice)"></div>
          </template>
        </a-calendar>
      </div>
    </a-popover>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'air-calendar',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    /**
     * 选中日期
     */
    value: {
      type: Object,
      validator: function (value) {
        return moment.isMoment(value)
      }
    },
    /**
     * 默认展示的日期
     */
    defaultValue: {
      type: Object,
      validator: function (value) {
        return moment.isMoment(value)
      },
      default: function () {
        return moment()
      }
    },
    /**
     * 开始时间，用来设置 disabled date；默认为 `当天`
     */
    beginTime: {
      type: Object,
      validator: function (value) {
        return moment.isMoment(value)
      },
      default: function () {
        return moment()
      }
    },
    /**
     * 结束时间，用来设置 disabled date；默认为 `两年后`
     */
    endTime: {
      type: Object,
      validator: function (value) {
        return moment.isMoment(value)
      },
      default: function () {
        return moment().add(2, 'y')
      }
    },
    /**
     * 获取价格日历 PromiseFunction(date: Moment)
     */
    fetchPricesData: Function,
    /**
     * 是否展示 loading 状态，结合获取价格日历请求使用
     */
    showLoading: {
      type: Boolean,
      default: false
    },
    /**
     * 是否显示删除按钮
     */
    allowClear: {
      type: Boolean,
      default: false
    },
    /**
     * 输入框提示
     */
    placeholder: String,
    /**
     * 输入框大小
     * @values default, small, large
     */
    size: {
      type: String,
      default: 'default'
    },
    /**
     * 弹出框位置
     * @values top, right, bottom, left, topLeft, topRight, rightTop, rightBottom, bottomLeft, bottomRight, leftTop, leftBottom
     */
    placement: {
      type: String,
      default: 'bottom'
    },
    /**
     * 是否展开选择框
     */
    visible: {
      type: Boolean,
      default: false
    },
    /**
     * moment 国际化
     */
    locale: {
      type: String,
      default: 'zh-cn'
    }
  },
  data () {
    return {
      model: null,
      // prevValue: undefined,
      // nextValue: undefined,
      // nextDefaultValue: undefined,
      panelValue: null,
      activeIndex: 0,
      show: false,
      fares: [],
      loading: false
    }
  },
  watch: {
    visible (newValue) {
      if (typeof newValue === 'undefined') {
        return
      }

      this.panelValue = null
      this.show = newValue
    }
  },
  computed: {
    startTime () {
      const { beginTime } = this

      return beginTime.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      })
    },
    prevDisable () {
      const { prevValue, startTime } = this

      if (!prevValue) {
        return false
      }

      return prevValue.clone().set('date', 1).isBefore(startTime)
    },
    minPrice () {
      const { fares } = this

      if (!fares || !fares.length) {
        return 0
      }

      return fares.slice().sort(function (a, b) {
        if (!a.price || isNaN(a.price) || parseInt(a.price) === 0) {
          return 1
        }

        if (!b.price || isNaN(b.price) || parseInt(b.price) === 0) {
          return -1
        }

        return a.price - b.price
      })[0].price
    },
    prevValue () {
      const { value, panelValue, defaultValue, beginTime } = this

      if (panelValue) {
        return panelValue.clone()
      }

      if (value) {
        return value.clone()
      }

      if (defaultValue.isAfter(beginTime)) {
        return defaultValue.clone()
      } else {
        return beginTime.clone()
      }
    },
    nextValue () {
      return this.prevValue.clone().add(1, 'M')
    }
  },
  created () {
    moment.locale(this.locale)

    this.$watch('value', (newValue) => {
      if (!newValue || !moment.isMoment(newValue)) {
        this.model = null
        return
      }

      this.model = newValue.clone()

      if (this.activeIndex === 1) {
        this.fares = []
      }

      this.activeIndex = 0
    }, {
      immediate: true
    })
  },
  methods: {
    // 展示和切换月份的时候请求价格日历数据
    fetchFare () {
      const { fetchPricesData, prevValue } = this

      if (typeof fetchPricesData === 'function') {
        const prom = fetchPricesData(prevValue.clone().set('date', 1))

        if (prom instanceof Promise) {
          this.loading = true

          prom.then(result => {
            this.fares = result
            this.loading = false
          }).catch(err => {
            console.error(err)
            this.loading = false
          })
        } else {
          throw new Error('fetchPricesData must be a function return Promise')
        }
      }
    },
    onVisibleChange (visible) {
      if (!visible) {
        this.panelValue = null
        this.close()
      } else {
        if (!this.fares.length) {
          this.fetchFare()
        }
      }

      /**
       * 显示隐藏的回调
       * @event visibleChange
       * @param { Boolean } visible - 显示 or 隐藏 { Boolean }
       */
      this.$emit('visibleChange', visible)
    },
    onPrevSelect (value) {
      if (value.month() !== this.prevValue.month()) {
        return
      }

      // this.prevValue = value
      this.activeIndex = 0
      this.panelValue = null
      this.show = false
      /**
       * 点击选择日期回调
       *
       * @event change
       * @param { Moment } value - 选择日期 { Moment }
       */
      this.$emit('change', value)
    },
    onNextSelect (value) {
      if (value.month() !== this.nextValue.month()) {
        return
      }

      // this.nextValue = value
      this.activeIndex = 1
      this.panelValue = null
      this.show = false
      /**
       * 点击选择日期回调
       *
       * @event change
       * @param { Moment } value - 选择日期 { Moment }
       */
      this.$emit('change', value)
    },
    onPrevChange () {
      const { prevDisable, prevValue, nextValue, startTime, model } = this

      if (prevDisable) {
        return
      }

      let subtrahend = 2
      let prevDate = prevValue.clone().subtract(2, 'M')

      if (prevDate.isBefore(startTime.clone().set('date', 1))) {
        subtrahend = 1
        prevDate = prevValue.clone().subtract(1, 'M')
      }

      const nextDate = nextValue.clone().subtract(subtrahend, 'M')

      this.panelValue = prevDate
      this.activeIndex = prevDate.isSame(model) ? 0 : nextDate.isSame(model) ? 1 : -1
      this.$nextTick(() => {
        this.fetchFare()
      })
    },
    onNextChange () {
      const { prevValue, nextValue, model } = this
      const prevDate = prevValue.clone().add(2, 'M')
      const nextDate = nextValue.clone().add(2, 'M')

      this.panelValue = prevDate
      this.activeIndex = prevDate.isSame(model) ? 0 : nextDate.isSame(model) ? 1 : -1
      this.$nextTick(() => {
        this.fetchFare()
      })
    },
    prevDisabledDate (date) {
      const { startTime, endTime } = this

      return date.isBefore(startTime) || date.isAfter(endTime)
    },
    nextDisabledDate (date) {
      const { endTime } = this

      return date.isAfter(endTime)
    },
    onClear () {
      this.model = null
      this.panelValue = null
      this.show = false
      /**
       * 点击选择日期回调
       *
       * @event change
       * @param { Moment } value - 选择日期 { Moment }
       */
      this.$emit('change', null)
    },
    close () {
      this.show = false
      /**
       * 选择框关闭事件
       *
       * @event close
       */
      this.$emit('close')
    }
  },
  filters: {
    format (value) {
      return value ? value.format('YYYY-MM-DD') : ''
    },
    date (value) {
      return value ? value.format('D') : ''
    },
    price (value, fares, minPrice) {
      if (!fares || !value) {
        return ''
      }

      const fare = fares.find(item => {
        return item.date === value.format('YYYY-MM-DD')
      })

      if (fare && fare.price) {
        let className = ''

        if (fare.price === minPrice) {
          className = 'min-price'
        }

        return `<span class="${className}">${fare.price}</span>`
      } else {
        return ''
      }
    },
    month (value) {
      return value ? value.format('M月 YYYY年') : '-'
    }
  }
}
</script>
